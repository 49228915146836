@use 'variables';
@use '@arbncoltd/arbn-components/themes/energy';

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #404040;
}

router-outlet {
  // set display:none on all router-outlets so they no longer
  // appear in layouts (potentially affecting margins, etc.)
  display: none;

  // ensure the adjacent sibling components within router-outlet
  // are flex elements, allowing them to fill the entire width
  // and height of their parent element
  & + * {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }
}

// disable number input spinners
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield; // firefox
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button { // webkit (chrome etc.)
    -webkit-appearance: none;
  }
}

// ensure all dividers fill their containers
mat-divider {
  width: 100%;
}

// *** TYPOGRAPHY ***

.bold-text {
  font-weight: 700;
}

.light-text {
  font-weight: 400;
}

.sub-text {
  color: #767676;
  font-weight: 400;
}

.regular-text {
  font-weight: 600;
}

.color-primary {
  color: variables.$primary;
}

.color-warn {
  color: variables.$warn;
}

.header-1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.header-2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.header-3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.header-4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.header-5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

// default
// .body-1 {
//   font-size: 14px;
//   font-weight: 600;
// }

// ***

// *** GENERAL GLOBAL STYLES ***

.layout-container {
  width: 100%;
  height: max-content;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex: 1 1 auto;
  padding: 64px 32px;
}

.fixed-container {
  width: 100%;
  max-width: 1050px;
}

.text-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.center-content {
  text-align: center;
}

.link {
  text-decoration: none;
  cursor: pointer;

  &.underline {
    text-decoration: underline;
  }
}

// specify "overflow: hidden" to ensure that any
// overflowing padding as a result of fxLayout="row wrap"
// is hidden to avoid excess scrolling
.row-wrap-container {
  overflow: hidden;
}

// hide the specified element by setting
// display to "none"
.hide-element {
  display: none !important;
}

// set the element as invisible
// (but still in the layout)
.invisible-element {
  visibility: hidden;
}

.overlay-panel-back-button {
  width: fit-content;
  color: variables.$primary;
  cursor: pointer;
  margin-left: -6px;
  user-select: none;
}

// ***

// *** SPECIFIC MATERIAL OVERRIDE STYLES ***

.chart-date-range-input-hidden {
  &.mat-date-range-input {
    width: 0;
  }
  .mat-date-range-input-container {
    display: none;
  }
}

.technology-nav-tabs {
  &.mat-tab-nav-bar.mat-tab-header {
    border-bottom: 0;
  }
  .mat-tab-list {
    border-bottom: 3px solid #E6E6E6;
  }
  .mat-ink-bar {
    margin-bottom: -3px;
  }
}

.view-nav-tabs {
  mat-tab-header {
    height: 40px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mdc-tab {
    height: 39px;
    font-weight: 600;
  }
}

.notify-snackbar {
  font-weight: 400;

  // set the action button text color
  & .mat-mdc-button.mat-mdc-snack-bar-action {
    color: variables.$primaryA700 !important;
    font-weight: 700;
  }
}

// global tooltip styles
.tooltip {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  background-color: #313131;
  // enable linebreaks in tooltips using &#13;
  white-space: pre-line;
}

// hide the year when viewing the 'year' view
// (grid of months) of the mat-date-range-picker
// when the calendar header component has the
// 'hide-year' class
.hide-year ~ .mat-calendar-content {
  mat-year-view .mat-calendar-body-label {
    display: none;
  }
}

// override the mat menu width so that it adjusts
// to fit it's content
.menu-width-fit-content {
  max-width: none !important;
  width: fit-content;
}

// set the style for overlay panels (dialogs etc.)
.overlay-panel {
  width: 100%;
  max-width: 468px !important;

  .mat-mdc-dialog-surface {
    padding: 64px;
  }

  // style for the "discard" panel
  &.discard { 
    width: fit-content;
    .mat-mdc-dialog-surface {
      padding: 24px;
    }
  }
}

// set a mat button to use the regular font weight
.button-regular-text {
  .mat-button-wrapper {
    font-weight: 600;
  }
}

// inherit color for material stroked buttons
button[mat-stroked-button].inherit-color {
  color: inherit;
}

// ***

// *** SPECIFIC ARBN-COMPONENTS OVERRIDE STYLES ***

// remove the element's border when the input is disabled
.input-no-border-disabled {
  & .arbn-form-field-input.arbn-form-field-disabled {
    border: 0;
  }
}

// remove the input's border
.input-no-border {
  & .arbn-form-field-container .arbn-form-field-input {
    border: 0;
  }
}

// reduce the height of the input
.input-compact {
  & .arbn-form-field-input {
    height: variables.$input-height !important;
  }
}

// set the height of the input to fit its content
.input-fit-height {
  & .arbn-form-field-input {
    height: fit-content;
  }
}

// remove any padding from the input
.input-no-padding {
  & .arbn-form-field-input {
    padding: 0;
  }
}

// display the input suffix at full opacity
.input-suffix-full-opacity {
  opacity: 1 !important;
}

arbn-label {
  // inherit the default font style
  &.inherit-font-style {
    font-size: inherit !important;
    font-weight: inherit !important;
  }
}

// remove any text styling from an optgroup
.arbn-optgroup-no-styling {
  & .arbn-optgroup {
    font-style: normal !important;
    font-weight: inherit !important;
    opacity: 1 !important;
  }
}

// the compact layout for an optgroup
.arbn-optgroup-compact {
  & .arbn-option {
    padding: 8px 12px;
  }
  & .arbn-option-content {
    opacity: 0.8;
  }
  & arbn-option:last-child .arbn-option {
    padding: 8px 12px 12px 12px;
  }
}

// ***


// *** GOOGLE MAPS OVERRIDE STYLES ***

.google-maps-disabled {
  pointer-events: none;
  & > .map-container {
    opacity: 0.4;
    filter: grayscale(100%);
    transition: 0.2s;
  }
}

// ***
