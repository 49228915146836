@use '@angular/material' as mat;
@use "sass:map";
@use 'themes/theme';

// default palette colors
$primary: mat.m2-get-color-from-palette(map.get(theme.$theme, primary));
$accent: mat.m2-get-color-from-palette(map.get(theme.$theme, accent));
$warn: mat.m2-get-color-from-palette(map.get(theme.$theme, warn));

// palette contrasts
$primary-contrast: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), default-contrast);
$accent-contrast: mat.m2-get-color-from-palette(map.get(theme.$theme, accent), default-contrast);
$warn-contrast: mat.m2-get-color-from-palette(map.get(theme.$theme, warn), default-contrast);

// primary palette shades
$primaryA700: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 'A700');
$primary50: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 50);
$primary5: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 5);
$primary15: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 15);
$primary50-25-opacity: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 50, 0.25);
$primary100: mat.m2-get-color-from-palette(map.get(theme.$theme, primary), 100);

// accent palette shades
$accent200: mat.m2-get-color-from-palette(map.get(theme.$theme, accent), 200);
$accent400: mat.m2-get-color-from-palette(map.get(theme.$theme, accent), 400);

// warn palette shades
$warn50: mat.m2-get-color-from-palette(map.get(theme.$theme, warn), 50);

$accent-hover: #313131;

// background color
$background: mat.m2-get-color-from-palette(map.get(theme.$theme, background), background);

$input-height: 45px;
